<template>
    <div class="home">

        <img class='background-img' src='../../assets/images/bg.jpg' alt=""/>
        <div class='card-wrapper'>
            <div class="top-banner mb-5">
                <img src="../../assets/images/logo1.png" alt="">
            </div>

            <div class="container-fluid" v-if="mount">
                <div>
                    <loading-animation></loading-animation><span class="m-2">Loading the data from the server</span>
                </div>
            </div>

            <div class="container-fluid" v-else>
                <div class="row">
                    <div class="col">
                        <s-form role="form" @submit="onSubmit">

                            <validated-input v-model="model.username" type="text" shadow="0"
                                             placeholder="" icon="fa fa-user" label="Enter your username"/>

                            <validated-input v-model="model.password" shadow="0"
                                             placeholder="" icon="fa fa-lock" type="password" label="Enter your password"/>

                            <button type="submit" class="xeo-btn">
                                <span v-if="loading">
                                    <loading-animation /> Validating Credential..
                                </span>
                                <span v-else>
                                    LOGIN
                                </span>
                            </button>

                        </s-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import { mapActions } from 'vuex';

export default {
    name  : 'Login',
    props : {
        info_message : {
            type    : String,
            default : ''
        }
    },

    data () {
        return {
            loading      : false,
            mount        : true,
            errorMessage : '',
            model        : {
                username : '',
                password : ''
            }
        };
    },

    mounted () {
        this.loadData();
    },

    methods : {
        ...mapActions(['setUser']),
        onSubmit () {
            const formValidator = this.$refs.formValidator;
            const that = this;
            this.loading = true;
            axios.form(urls.auth.login, this.model, ['username', 'password']).then(function (response) {
                const json = response.data;
                if (json.success === true) {
                    that.loading = false;
                    const redirectUrl = sessionStorage.getItem('redirectPath');
                    that.setUser(json.user);
                    that.$router.push(redirectUrl || { path : '/' });
                } else {
                    that.loading = false;
                    formValidator.setErrors(json.errors);
                    that.model.username = '';
                    that.model.password = '';
                    that.errorMessage = json.errors.__all__ || '';
                }
                that.loading = false;
            }).catch(function () {
                that.errorMessage = 'Invalid credentials.';
                that.model.username = '';
                that.model.password = '';
                that.loading = false;
            });
        },

        async loadData () {
            const response = await axios.get(urls.auth.status);
            console.log('response : ', response.data);
            if (response.data['public-key']) {
                this.mount = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
